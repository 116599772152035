import { Role } from '../features/auth/auth.slice';

export interface IPermissions {
  editGeneralSettings?: boolean;
  editPrivacyPolicySettings?: boolean;
  editTermsSettings?: boolean;
  editFAQSettings?: boolean;
  editContactInfoSettings?: boolean;
  editScreenAppSettings?: boolean;
  editHelpSettings?: boolean;
  resetUserPassword?: boolean;
  screenAppIntegration?: boolean;
  viewReports?: boolean;
  viewAdmins?: boolean;
  viewCustomers?: boolean;
  viewMembers?: boolean;
  editMembers?: boolean;
  promoteCustomers?: boolean;
  suspendCustomers?: boolean;
  viewGroups?: boolean;
  scheduleMeeting?: boolean;
  instantMeeting?: boolean;
  addMembers?: boolean;
  addLivestreamings?: boolean;
  viewLivestreamings?: boolean;
  updateLivestreamings?: boolean;
  deleteLivestreamings?: boolean;
}

export const rolePermissions = {
  'super-admin': {
    editProfileSettings: true,
    editGeneralSettings: true,
    editPrivacyPolicySettings: true,
    editTermsSettings: true,
    editFAQSettings: true,
    editContactInfoSettings: true,
    editScreenAppSettings: true,
    editHelpSettings: true,
    resetUserPassword: true,
    screenAppIntegration: true,
    viewReports: true,
    viewAdmins: true,
    viewMembers: true,
    editMembers: true,
    viewGroups: true,
    scheduleMeeting: true,
    instantMeeting: true,
    addMembers: true,
    viewMeetings: true,
    addLivestreamings: true,
    viewLivestreamings: true,
    updateLivestreamings: true,
    deleteLivestreamings: true,
  },
  admin: {
    editProfileSettings: true,
    editGeneralSettings: true,
    editPrivacyPolicySettings: true,
    editTermsSettings: true,
    editFAQSettings: true,
    editContactInfoSettings: true,
    editScreenAppSettings: true,
    editHelpSettings: true,
    resetUserPassword: true,
    screenAppIntegration: true,
    viewReports: true,
    viewAdmins: true,
    viewMembers: true,
    editMembers: true,
    viewGroups: true,
    scheduleMeeting: true,
    instantMeeting: true,
    addMembers: true,
    viewMeetings: true,
    addLivestreamings: true,
    viewLivestreamings: true,
    updateLivestreamings: true,
    deleteLivestreamings: true,
  },
  member: {
    editProfileSettings: true,
    editGeneralSettings: false,
    editPrivacyPolicySettings: false,
    editTermsSettings: false,
    editFAQSettings: false,
    editContactInfoSettings: false,
    editScreenAppSettings: false,
    editHelpSettings: false,
    resetUserPassword: false,
    screenAppIntegration: false,
    viewReports: false,
    viewAdmins: false,
    viewMembers: true,
    editMembers: false,
    viewGroups: false,
    scheduleMeeting: true,
    instantMeeting: true,
    addMembers: false,
    viewMeetings: true,
    addLivestreamings: false,
    viewLivestreamings: true,
    updateLivestreamings: false,
    deleteLivestreamings: false,
  },
};

export const filterRolePermissions = (role: Role): IPermissions => {
  return rolePermissions[role as keyof typeof rolePermissions];
};

export const isPermissionAvailable = (
  permissions: IPermissions,
  feature: string
): boolean => {
  if (!permissions || !feature) return false;
  if (permissions[feature as keyof typeof permissions]) return true;
  return false;
};
