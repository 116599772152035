import React, { memo } from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      color: theme.palette.secondary.main,
      minHeight: 'unset',

      '& .MuiTab-root': {
        textTransform: 'capitalize',
        fontFamily: 'Helvetica Neue',
        fontSize: theme.typography.h6.fontSize,
        minWidth: '80px !important',
        // [theme.breakpoints.down('md')]: {
        //   fontSize: '14px',
        //   minWidth: 'unset',
        // },
        // [theme.breakpoints.down('xs')]: {
        //   fontSize: 12,
        //   minHeight: 36,
        // },
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& .MuiTabs-fixed': {
        padding: '3px',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },

      '& .Mui-selected': {
        backgroundColor: theme.palette.common.white,
        borderRadius: 6,
        boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.12)',

        '& .MuiTab-wrapper': {
          color: theme.palette.primary.main,
          fontSize: theme.typography.h6.fontSize,
          fontWeight: 700,
          border: 'none !important',
          // [theme.breakpoints.down('md')]: {
          //   fontSize: '14px',
          // },
          // [theme.breakpoints.down('xs')]: {
          //   fontSize: 12,
          // },
        },
      },
    },
  });
};

export interface TabComponentProps extends WithStyles<typeof styles> {
  id?: string;

  className?: any;

  label: string;
  index: number;
}

export const TabComponent: React.FC<TabComponentProps> = ({
  classes,
  className,
  label,
  index,
  ...other
}) => {
  const a11yProps = (x: any) => {
    return {
      id: `simple-tab-${x}`,
      'aria-controls': `simple-tabpanel-${x}`,
    };
  };

  return (
    <Tab label={label} {...other} {...a11yProps(index)} className={className} />
  );
};

export default memo(withStyles(styles)(TabComponent));
