import React, { memo } from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '../Typography';

const styles = (theme: Theme) => {
  return createStyles({
    tabPanelWrapper: {
      overflow: 'auto',
      flexGrow: 1,
    },
    tabPanel: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    tabPanelHeadar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '40px 0 24px',
      minHeight: 40,
    },
    tabPanelTitle: {
      fontFamily: 'Helvetica Neue',
      color: theme.palette.primary.main,
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        // fontSize: 14,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '105vh',
      },
    },
  });
};

export interface TabPanelProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  index: any;
  title?: any;
  value: any;
  itemsGap?: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  classes,
  children,
  title,
  index,
  value,
  itemsGap = '0',
}) => {
  if (value !== index) return null;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanelWrapper}
    >
      {value === index && (
        <div className={classes.tabPanel}>
          {title && (
            <div className={classes.tabPanelHeadar}>
              <Typography
                variant="caption"
                component="h3"
                className={classes.tabPanelTitle}
              >
                {title}
              </Typography>
            </div>
          )}
          <div
            className={classes.contentWrapper}
            style={{ gap: `${itemsGap}` }}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(withStyles(styles)(TabPanel));
