/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useState } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  MoreHoriz,
  Share,
  DeleteOutline,
  VideocamOutlined,
  Edit,
} from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';
import { FormattedMessage, FormattedRelativeTime, useIntl } from 'react-intl';

import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { Typography } from '../../components/Typography';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { ScheduleMeetingButtons } from '../scheduleMeeting';
import { ShareMeetingModal, DeleteMeetingModal } from './components';
import { emptyMeetingsViewIcon } from '../../assets/icons';
import styles from './meetings.styles';
import { Card } from '../../components/Card';
import { getMeetingName } from '../../helper/translationFunctions';

interface IMeetingsView extends WithStyles<typeof styles> {
  user: any;
  meetings: Array<any>;
  joinToMeetingButtonClick: (data: any) => void;
  removeMeeting: (id: string) => void;
  openEditMeetingModal: (data: any) => void;
  openViewMeetingModal: (data: any) => void;
}

const MeetingsView: React.FC<IMeetingsView> = ({
  classes,
  user,
  meetings,
  joinToMeetingButtonClick,
  removeMeeting,
  openEditMeetingModal,
  openViewMeetingModal,
}: IMeetingsView) => {
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [recentAnchor, setRecentAnchor] = useState<null | HTMLElement>(null);
  const [upcomingAnchor, setUpcomingAnchor] = useState<null | HTMLElement>(
    null
  );
  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);
  const [isDeleteMeetingOpen, setIsDeleteMeetingOpen] = useState(false);
  const intl = useIntl();

  const handleOpenViewModal = (data: any) => {
    openViewMeetingModal(data);
  };

  const getMoreOptionList = (entry: string, data: any) => {
    const optionList = [
      {
        label: intl.formatMessage({
          id: 'share_invitation',
        }),
        id: 'meeting-share_invitation',
        onClick: () => setIsShareMeetingOpen(true),
        icon: <Share fontSize="small" />,
      },
      {
        label: intl.formatMessage({
          id: 'view',
        }),
        id: 'meeting-view',
        onClick: () => handleOpenViewModal(data),
        icon: <VideocamOutlined fontSize="small" />,
      },
    ];
    if (entry === 'edit') {
      optionList.push({
        label: intl.formatMessage({
          id: 'edit',
        }),
        id: 'meeting-edit',
        onClick: () => openEditMeetingModal(data),
        icon: <Edit fontSize="small" />,
      });
    }
    if (entry === 'delete') {
      optionList.push({
        label: intl.formatMessage({
          id: 'delete',
        }),
        id: 'meeting-delete',
        onClick: () => setIsDeleteMeetingOpen(true),
        icon: <DeleteOutline fontSize="small" />,
      });
    }
    if (entry === 'editDelete') {
      optionList.push({
        label: intl.formatMessage({
          id: 'edit',
        }),
        id: 'meeting-edit',
        onClick: () => openEditMeetingModal(data),
        icon: <Edit fontSize="small" />,
      });
      optionList.push({
        label: intl.formatMessage({
          id: 'delete',
        }),
        id: 'meeting-delete',
        onClick: () => setIsDeleteMeetingOpen(true),
        icon: <DeleteOutline fontSize="small" />,
      });
    }
    return optionList;
  };

  const [MeetingOptionList, setMeetingOptionList] = useState(
    getMoreOptionList('none', null)
  );

  const isMemberHosted = (host: any) => {
    if (user?.role === 'member' && host && host.id) {
      return host.id === user.id;
    }

    return false;
  };

  const handleClickPastMtnMoreOption = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: any
  ) => {
    e.stopPropagation();
    setSelectedMeeting(data);
    setRecentAnchor(e.currentTarget);
    if (user?.role === 'member' && data.type === 'schedule') {
      if (isMemberHosted(data?.host)) {
        setMeetingOptionList(getMoreOptionList('editDelete', data));
      } else {
        setMeetingOptionList(getMoreOptionList('none', data));
      }
    } else if (user?.role === 'member' && data.type === 'instant') {
      setMeetingOptionList(getMoreOptionList('delete', data));
    } else if (user?.role !== 'member' && data.type === 'schedule') {
      setMeetingOptionList(getMoreOptionList('editDelete', data));
    } else if (user?.role !== 'member' && data.type === 'instant') {
      setMeetingOptionList(getMoreOptionList('delete', data));
    } else {
      setMeetingOptionList(getMoreOptionList('none', data));
    }
  };

  const handleClickUpcomingMtnMoreOption = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: any
  ) => {
    e.stopPropagation();
    setSelectedMeeting(data);
    setUpcomingAnchor(e.currentTarget);
    if (user?.role === 'member' && data.type === 'schedule') {
      if (isMemberHosted(data?.host)) {
        setMeetingOptionList(getMoreOptionList('editDelete', data));
      } else {
        setMeetingOptionList(getMoreOptionList('none', data));
      }
    } else if (user?.role === 'member' && data.type === 'instant') {
      setMeetingOptionList(getMoreOptionList('delete', data));
    } else if (user?.role !== 'member' && data.type === 'schedule') {
      setMeetingOptionList(getMoreOptionList('editDelete', data));
    } else if (user?.role !== 'member' && data.type === 'instant') {
      setMeetingOptionList(getMoreOptionList('delete', data));
    } else {
      setMeetingOptionList(getMoreOptionList('none', data));
    }
  };

  const handleCloseRecentMtnActionBtn = () => {
    // setSelectedMeeting(null);
    setRecentAnchor(null);
  };

  const handleCloseUpcomingMtnActionBtn = () => {
    // setSelectedMeeting(null);
    setUpcomingAnchor(null);
  };

  const _handleCloseShareMeetingModal = () => {
    setIsShareMeetingOpen(false);
  };

  const _handleCloseDeleteMeetingModal = () => {
    setIsDeleteMeetingOpen(false);
  };

  const tableColumns = [
    {
      name: 'id',
      label: intl.formatMessage({
        id: 'id',
      }),
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            width: '20dvw',
            maxWidth: 340,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }),
      },
    },
    {
      name: 'beginTime',
      label: intl.formatMessage({
        id: 'started_time',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'host',
      label: intl.formatMessage({
        id: 'host',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            textTransform: 'Capitalize',
          },
        }),
      },
    },
    {
      name: 'status',
      label: intl.formatMessage({
        id: 'status',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            textTransform: 'Capitalize',
          },
        }),
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: 150,
          },
        }),
      },
    },
    {
      name: 'more',
      label: intl.formatMessage({
        id: 'more',
      }),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: 100,
            paddingLeft: 24,
          },
        }),
      },
    },
  ];

  const getActionButtonData = (row: any) => {
    switch (row.meetingType) {
      case 'upcoming':
        return {
          color: 'secondary',
          label: intl.formatMessage({
            id: 'launch',
          }),
          onClick: (e: any) => {
            e.stopPropagation();
            joinToMeetingButtonClick(row);
          },
        };
      case 'ongoing':
        return {
          color: 'primary',
          label: intl.formatMessage({
            id: 'join',
          }),
          onClick: (e: any) => {
            e.stopPropagation();
            joinToMeetingButtonClick(row);
          },
        };
      case 'completed':
        return {
          color: 'tertiary',
          label: intl.formatMessage({
            id: row.type === 'instant' ? 'rejoin' : 'change',
          }),
          onClick: (e: any) => {
            e.stopPropagation();
            if (row.type === 'instant') {
              joinToMeetingButtonClick(row);
            } else {
              openEditMeetingModal(row);
            }
          },
        };
      default:
        return {};
    }
  };

  const getMoreOptionData = (
    // e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: any
  ) => {
    switch (row.meetingType) {
      case 'upcoming':
        return {
          onClick: (e: any) => handleClickUpcomingMtnMoreOption(e, row),
        };
      case 'ongoing':
        return {
          onClick: (e: any) => handleClickPastMtnMoreOption(e, row),
        };
      case 'completed':
        return {
          onClick: (e: any) => handleClickPastMtnMoreOption(e, row),
        };
      default:
        return {};
    }
  };

  const tableRowEvent = useCallback(() => {
    return map(meetings, (row) => {
      return {
        ...row,
        name: getMeetingName(intl, row),
        beginTime: moment(row.beginTime).format('YYYY-MM-DD HH:mm:ss'),
        host: row.host.profile.name,
        status: intl.formatMessage({
          id: row.meetingType,
        }),
        action: (
          <Button
            {...getActionButtonData(row)}
            id="meeting-table-action-button"
            variant="contained"
            customStyles={classes.tableActionButton}
          />
        ),
        more: (
          <IconButton
            id="meeting-table-more"
            size="small"
            {...getMoreOptionData(row)}
          >
            <MoreHoriz color="primary" fontSize="inherit" />
          </IconButton>
        ),
      };
    });
  }, [meetings]);

  const noMeetingsView = () => {
    return (
      <NoDataLayout
        image={emptyMeetingsViewIcon}
        heading={intl.formatMessage({
          id: 'dont_have_previous_meeting_to_show',
        })}
      >
        <ScheduleMeetingButtons />
      </NoDataLayout>
    );
  };

  if (meetings.length === 0) return noMeetingsView();

  const handleRowOnClick = (data: any) => {
    const meetingData = meetings.find((meeting) => meeting.id === data[0]);
    setSelectedMeeting(meetingData);

    if (meetingData.type === 'instant') {
      openViewMeetingModal(meetingData);
    } else {
      openEditMeetingModal(meetingData);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="meetings-view">
        <Typography
          className={classes.heading}
          variant="h3"
          component="h2"
          color="textPrimary"
        >
          <FormattedMessage id="meetings" />
        </Typography>
        <div className={classes.buttonWrapper}>
          <ScheduleMeetingButtons />
        </div>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            title={intl.formatMessage(
              {
                id: 'total_meetings_count',
              },
              {
                count: meetings.length,
              }
            )}
            id="all-meeting"
            columns={tableColumns}
            data={tableRowEvent()}
            customStyles={classes.table}
            pagination
            filter
            onRowClick={handleRowOnClick}
          />
        </Card>
      </div>
      <Dropdown
        id="recent-more-option-button"
        open={Boolean(selectedMeeting) && Boolean(recentAnchor)}
        anchorRef={recentAnchor}
        handleClose={handleCloseRecentMtnActionBtn}
        itemList={MeetingOptionList}
      />
      <Dropdown
        id="upcoming-more-option-button"
        open={Boolean(selectedMeeting) && Boolean(upcomingAnchor)}
        anchorRef={upcomingAnchor}
        handleClose={handleCloseUpcomingMtnActionBtn}
        itemList={MeetingOptionList}
      />
      {isShareMeetingOpen && (
        <ShareMeetingModal
          open={isShareMeetingOpen}
          handleClose={_handleCloseShareMeetingModal}
          meeting={selectedMeeting}
        />
      )}
      {isDeleteMeetingOpen && (
        <DeleteMeetingModal
          open={isDeleteMeetingOpen}
          meeting={selectedMeeting}
          handleClose={_handleCloseDeleteMeetingModal}
          removeMeeting={removeMeeting}
        />
      )}
    </div>
  );
};

export default memo(withStyles(styles)(MeetingsView));
