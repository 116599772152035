/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { map } from 'lodash';
import { Table } from '../../../components/Table';
import { Button } from '../../../components/Button';
import { getMeetingName } from '../../../helper/translationFunctions';
import { IRequestParams } from '../../../components/types';
import { TableSearch } from '../../../components/Search';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    table: {
      '& .custom-table-title': {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 700,
        fontFamily: 'Helvetica Neue',
        textTransform: 'capitalize',
      },
    },
    tableActionButton: {
      '& .basic-button-class': {
        // width: '100%',
      },
    },
    tableSearch: {
      position: 'absolute',
      top: 14,
      right: 31,
      zIndex: 105,
    },
  });
};

export interface IOngoingMeetingsTableProps extends WithStyles<typeof styles> {
  meetings: Array<any>;
  joinToMeetingButtonClick: (data: any) => void;
  handleSearch: (data: IRequestParams) => void;
}

const OngoingMeetingsTable = ({
  classes,
  meetings,
  joinToMeetingButtonClick,
  handleSearch,
}: IOngoingMeetingsTableProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const tableColumns = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            width: '20dvw',
            maxWidth: window.innerWidth < 1500 ? 120 : 340,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }),
      },
    },
    {
      name: 'participants',
      label: intl.formatMessage({
        id: 'participants',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            textTransform: 'Capitalize',
            width: 150,
          },
        }),
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: 155,
          },
        }),
      },
    },
  ];

  const tableRowEvent = useCallback(() => {
    return map(meetings, (row) => {
      return {
        ...row,
        name: getMeetingName(intl, row),
        participants: row?.members.length,
        action: (
          <Button
            id="ongoing-meetings-table-action-button"
            variant="contained"
            color="primary"
            label={intl.formatMessage({
              id: isMobile ? 'join' : 'join_call',
            })}
            onClick={(e: any) => {
              e.stopPropagation();
              joinToMeetingButtonClick(row);
            }}
            customStyles={classes.tableActionButton}
          />
        ),
      };
    });
  }, [meetings]);

  return (
    <>
      <TableSearch
        handleSearch={handleSearch}
        customStyles={classes.tableSearch}
      />
      <Table
        title={intl.formatMessage({
          id: 'ongoing_meetings',
        })}
        id="ongoing-meetings-table"
        columns={tableColumns}
        data={tableRowEvent()}
        customStyles={classes.table}
        pagination
        denseControl={false}
      />
    </>
  );
};

export default memo(withStyles(styles)(OngoingMeetingsTable));
