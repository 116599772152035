/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectMeetings,
  fetchAllMeetingsAsync,
  removeMeetingAsync,
} from '../meetings/meetings.slice';
import { selectAuth } from '../auth/auth.slice';
import {
  instantMeetingAsync,
  actions as scheduleMeetingActions,
  selectSchesuleMeeting,
} from '../scheduleMeeting/scheduleMeeting.slice';
import DashboardView from './dashboard.view';
import { generateMeetingUrl } from '../../helper/conference';
import { IRequestParams } from '../../components/types';
import { actions as appActions } from '../app/app.slice';

export interface IDashboardContainer {}

const DashboardContainer: React.FC<IDashboardContainer> = ({}: IDashboardContainer) => {
  const scheduleMeeting = useAppSelector(selectSchesuleMeeting);
  const meetings = useAppSelector(selectMeetings);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [upcomingMeetings, setUpcomingMeetings] = useState<Array<any>>([]);
  const [ongoingMeetings, setOngoingMeetings] = useState<Array<any>>([]);
  const [recentMeetings, setRecentMeetings] = useState<Array<any>>([]);
  const [todayMeetings, setTodayMeetings] = useState<Array<any>>([]);
  const [allMeetings, setAllMeetings] = useState<Array<any>>([]);

  useEffect(() => {
    if (scheduleMeeting.success && scheduleMeeting.isSpeeddialInprogress) {
      dispatch(scheduleMeetingActions.closeSpeeddial());
      const url = generateMeetingUrl(scheduleMeeting.data?.location, false);
      history.push(url);
    }
  }, [scheduleMeeting.success]);

  useEffect(() => {
    if (
      scheduleMeeting.meetingLocation &&
      scheduleMeeting.isSpeeddialInprogress
    ) {
      dispatch(scheduleMeetingActions.closeSpeeddial());
      const url = generateMeetingUrl(scheduleMeeting.meetingLocation, false);
      dispatch(scheduleMeetingActions.setMeetingLocation(''));
      history.push(url);
    }
  }, [scheduleMeeting.meetingLocation]);

  useEffect(() => {
    dispatch(fetchAllMeetingsAsync(null));
  }, []);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10102'));
  }, []);

  useEffect(() => {
    const now = new Date().toISOString();
    // const dayStart = moment().startOf('day').toISOString();
    const dayEnd = moment().endOf('day').toISOString();

    const past = meetings?.list
      ?.filter(({ beginTime }) => beginTime <= now)
      .sort((a, b) => (a.beginTime - b.beginTime ? 1 : -1));
    const today = meetings?.list
      ?.filter(({ beginTime }) => beginTime >= now && beginTime <= dayEnd)
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));
    const all = meetings?.list?.map((obj) => {
      let meetingType;
      if (obj.beginTime >= now) {
        meetingType = 'upcoming';
      } else if (obj.beginTime <= now && obj.endTime >= now) {
        meetingType = 'ongoing';
      } else {
        meetingType = 'past';
      }
      return {
        ...obj,
        meetingType,
      };
    });

    const ongoingMeetingsList = all
      ?.filter((meeting) => meeting.meetingType === 'ongoing')
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));
    const upcomingMeetingsList = all
      ?.filter((meeting) => meeting.meetingType === 'upcoming')
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));
    const pastMeetingsList = all
      ?.filter((meeting) => meeting.meetingType === 'past')
      .sort((a, b) => (a.beginTime - b.beginTime ? 1 : -1))
      .slice(0, 10); // latest 10 past meetings
    const allMeetingsForDashboardView = [
      ...ongoingMeetingsList,
      ...upcomingMeetingsList,
      ...pastMeetingsList,
    ];

    setTodayMeetings(today);
    setRecentMeetings(pastMeetingsList);
    setUpcomingMeetings(upcomingMeetingsList);
    setOngoingMeetings(ongoingMeetingsList);
    setAllMeetings(allMeetingsForDashboardView);
  }, [meetings.list]);

  const _scheduleMeetingOnClick = () => {
    dispatch(scheduleMeetingActions.openScheduleModal());
  };

  const _instantMeetingOnClick = () => {
    dispatch(scheduleMeetingActions.openInstantMeetingModal());
  };

  const _handleSpeeddial = (data: any) => {
    dispatch(scheduleMeetingActions.openSpeeddial());
    dispatch(instantMeetingAsync(data));
  };

  const _handleSpeeddialJoinCall = (meetingLocation: string) => {
    dispatch(scheduleMeetingActions.openSpeeddial());
    dispatch(scheduleMeetingActions.setMeetingLocation(meetingLocation));
  };

  const _joinToMeetingButtonClick = (data: any) => {
    const url = generateMeetingUrl(data?.location, false);
    history.push(url);
  };

  const _handleSearch = (data: IRequestParams) => {
    dispatch(fetchAllMeetingsAsync(data));
  };

  const _openEditMeetingModal = (data: any) => {
    dispatch(scheduleMeetingActions.openEditMeetingModal(data));
  };

  const _openViewMeetingModal = (data: any) => {
    dispatch(scheduleMeetingActions.openViewMeetingModal(data));
  };

  const _removeMeeting = (id: string) => {
    dispatch(removeMeetingAsync(id));
  };

  return (
    <>
      <DashboardView
        upcomingMeetings={upcomingMeetings}
        ongoingMeetings={ongoingMeetings}
        recentMeetings={recentMeetings}
        todayMeetings={todayMeetings}
        allMeetings={allMeetings}
        user={auth?.user}
        permissions={auth?.permissions}
        overview={meetings?.overview || []}
        scheduleMeetingOnClick={_scheduleMeetingOnClick}
        instantMeetingOnClick={_instantMeetingOnClick}
        joinToMeetingButtonClick={_joinToMeetingButtonClick}
        openEditMeetingModal={_openEditMeetingModal}
        openViewMeetingModal={_openViewMeetingModal}
        handleSpeeddial={_handleSpeeddial}
        handleSpeeddialJoinCall={_handleSpeeddialJoinCall}
        handleSearch={_handleSearch}
        removeMeeting={_removeMeeting}
      />
      {/* <ScheduleMeetingModal />
      <InstantMeetingmodal /> */}
    </>
  );
};

export default memo(DashboardContainer);
