/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { map } from 'lodash';
import { Table } from '../../../../components/Table';
import { Button } from '../../../../components/Button';
import { getMeetingName } from '../../../../helper/translationFunctions';
import { TableSearch } from '../../../../components/Search';
import { IRequestParams } from '../../../../components/types';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    table: {
      '& .custom-table-title': {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 700,
        fontFamily: 'Helvetica Neue',
      },
    },
    tableActionButton: {
      '& .basic-button-class': {
        width: 112,
      },
    },
    tableSearch: {
      position: 'absolute',
      top: 14,
      right: 31,
      zIndex: 105,
    },
  });
};

export interface IStreamingsTableProps extends WithStyles<typeof styles> {
  streamings: Array<any>;
  joinToMeetingButtonClick: (location: string, userType: string) => void;
  handleSearch: (data: IRequestParams) => void;
}

const StreamingsTable = ({
  classes,
  streamings,
  joinToMeetingButtonClick,
  handleSearch,
}: IStreamingsTableProps) => {
  const intl = useIntl();

  const tableColumns = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            width: '20dvw',
            maxWidth: window.innerWidth < 1500 ? 120 : 340,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }),
      },
    },
    {
      name: 'status',
      label: intl.formatMessage({
        id: 'status',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            textTransform: 'Capitalize',
          },
        }),
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: 150,
          },
        }),
      },
    },
  ];

  const getActionButtonData = (row: any) => {
    switch (row.meetingType) {
      case 'upcoming':
        return {
          color: 'secondary',
          label: intl.formatMessage({
            id: 'start_call',
          }),
          onClick: (e: any) => {
            e.stopPropagation();
            if (joinToMeetingButtonClick) {
              joinToMeetingButtonClick(
                row.location,
                row.livestreaming.userType
              );
            }
          },
        };
      case 'ongoing':
        return {
          color: 'primary',
          label: intl.formatMessage({
            id: 'join_call',
          }),
          onClick: (e: any) => {
            e.stopPropagation();
            if (joinToMeetingButtonClick) {
              joinToMeetingButtonClick(
                row.location,
                row.livestreaming.userType
              );
            }
          },
        };
      case 'completed':
        return {
          disabled: true,
          label: intl.formatMessage({
            id: 'ended',
          }),
          onClick: (e: any) => {
            e.stopPropagation();
            // if (row.type === 'instant') {
            //   joinToMeetingButtonClick(row);
            // } else {
            //   // openEditMeetingModal(row);
            // }
          },
        };
      default:
        return {};
    }
  };

  const tableRowEvent = useCallback(() => {
    return map(streamings, (row) => {
      return {
        ...row,
        name: getMeetingName(intl, row),
        status: intl.formatMessage({
          id: row.meetingType,
        }),
        action: (
          <Button
            {...getActionButtonData(row)}
            id="meeting-table-action-button"
            variant="contained"
            customStyles={classes.tableActionButton}
          />
        ),
      };
    });
  }, [streamings]);

  return (
    <>
      <TableSearch
        handleSearch={handleSearch}
        customStyles={classes.tableSearch}
      />
      <Table
        title={intl.formatMessage({
          id: 'streamings',
        })}
        id="all-streamings"
        columns={tableColumns}
        data={tableRowEvent()}
        customStyles={classes.table}
        pagination
        denseControl={false}
      />
    </>
  );
};

export default memo(withStyles(styles)(StreamingsTable));
