/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  connectYoutubeAsync,
  disconnectYoutubeAsync,
  readLivestreamingsAsync,
  getYoutubeStatusAsync,
  selectLivestreaming,
} from '../../../livestreaming/livestreaming.slice';

import { actions as appActions } from '../../../app/app.slice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectAuth } from '../../../auth/auth.slice';
import { IRequestParams } from '../../../../components/types';
import StreamingsTableView from './streamingsTable.view';
import {
  generateLivestreamingUrl,
  generateMeetingUrl,
} from '../../../../helper/conference';

const StreamingsTableContainer: React.FC<unknown> = () => {
  const dispatch = useAppDispatch();
  const livestreaming = useAppSelector(selectLivestreaming);
  const auth = useAppSelector(selectAuth);
  const history = useHistory();

  const [allStreamings, setAllStreamings] = useState<Array<any>>([]);

  useEffect(() => {
    if (auth?.user?.role !== 'member') {
      dispatch(getYoutubeStatusAsync());
    }
  }, []);

  useEffect(() => {
    dispatch(readLivestreamingsAsync(null));
  }, []);

  useEffect(() => {
    const now = new Date().toISOString();
    const all = livestreaming?.list?.map((obj) => {
      let meetingType;
      if (obj.beginTime >= now) {
        meetingType = 'upcoming';
      } else if (obj.beginTime <= now && obj.endTime >= now) {
        meetingType = 'ongoing';
      } else {
        meetingType = 'completed';
      }
      return {
        ...obj,
        meetingType,
      };
    });

    const sortStreamings = all.sort(
      (a, b) =>
        new Date(b.beginTime).getTime() - new Date(a.beginTime).getTime()
    );

    setAllStreamings(sortStreamings);
  }, [livestreaming.list]);

  const _handleSearch = (data: IRequestParams) => {
    dispatch(readLivestreamingsAsync(data));
  };

  const connectYoutube = () => {
    dispatch(connectYoutubeAsync());
  };
  const disconnectYoutube = () => {
    dispatch(disconnectYoutubeAsync());
  };

  const _joinToStreamingButtonClick = (location: string, userType: string) => {
    let url = generateMeetingUrl(location, false);
    if (userType === 'viewer') {
      url = generateLivestreamingUrl(location, false);
    }
    history.push(url);
  };
  return (
    <StreamingsTableView
      joinToMeetingButtonClick={_joinToStreamingButtonClick}
      streamings={allStreamings}
      handleSearch={_handleSearch}
    />
  );
};

export default memo(StreamingsTableContainer);
