import React from 'react';
import { useIntl } from 'react-intl';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { DateRange, VideocamOutlined } from '@material-ui/icons';
import { IconButton } from '../../../components/Button';
import { IPermissions } from '../../../app/permission';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

interface IScheduleMeetingButtonsProps extends WithStyles<typeof styles> {
  permissions: IPermissions;
  scheduleMeetingBtnClick: () => void;
  instanteMeetingBtnClick: () => void;
}

const ScheduleMeetingButtons: React.FC<IScheduleMeetingButtonsProps> = ({
  classes,
  permissions,
  scheduleMeetingBtnClick,
  instanteMeetingBtnClick,
}: IScheduleMeetingButtonsProps) => {
  const intl = useIntl();
  return (
    <>
      {permissions.instantMeeting ? (
        <IconButton
          id="create-instant-meeting-button"
          label={intl.formatMessage({
            id: 'instant_meeting',
          })}
          icon={<VideocamOutlined />}
          variant="outlined"
          onClick={instanteMeetingBtnClick}
        />
      ) : null}
      {permissions.scheduleMeeting ? (
        <IconButton
          id="schedule-meeting-button"
          label={intl.formatMessage({
            id: 'schedule_meeting',
          })}
          icon={<DateRange />}
          variant="contained"
          onClick={scheduleMeetingBtnClick}
        />
      ) : null}
    </>
  );
};

export default withStyles(styles)(ScheduleMeetingButtons);
