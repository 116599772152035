import React, { memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { validateEmail, validatePassword } from '../../../helper/validation';
import { TextField, PasswordTextField } from '../../../components/TextField';
import { IUsersState } from '../users.slice';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IAddUsersModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose: () => void;
  handleAddUser: (data: any) => void;
  selectUser: IUsersState;
}

const AddUsersModal = ({
  classes,
  open,
  handleClose,
  handleAddUser,
  selectUser,
}: IAddUsersModalProps) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState({ error: false, value: '' });
  const [password, setPassword] = useState({ error: false, value: '' });
  const intl = useIntl();

  useEffect(() => {
    handleClose();
  }, [selectUser.loading]);

  const _validate = () => {
    let isValidate = true;
    if (!email.value || email.error) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }
    if (!password.value || password.error) {
      isValidate = false;
      setPassword({ ...password, error: true });
    }

    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const userData = {
        name,
        email: email.value,
        password: password.value,
      };
      handleAddUser(userData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName(e.target.value);
  };

  const emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  const passwordOnChange = (e: { target: { value: string } }) => {
    const error = validatePassword(e.target.value);
    setPassword({ value: e.target.value, error });
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'add_admin',
      })}
      modalDescription={intl.formatMessage({
        id: 'add_admin_description',
      })}
      id="add-user-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'add_admin',
      })}
    >
      <TextField
        placeholder={intl.formatMessage({
          id: 'name',
        })}
        onChange={nameOnChange}
      />
      <TextField
        id="add-user-email"
        error={email.error}
        placeholder={intl.formatMessage({
          id: 'email',
        })}
        required
        onChange={emailOnChange}
        helperText={
          email.error &&
          intl.formatMessage({
            id: 'please_insert_valid_email',
          })
        }
      />
      <PasswordTextField
        id="add-user-password"
        label={intl.formatMessage({
          id: 'password',
        })}
        required
        onChange={passwordOnChange}
        error={password.error}
        helperText={
          password.error &&
          intl.formatMessage({
            id: 'please_insert_valid_password',
          })
        }
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(AddUsersModal));
