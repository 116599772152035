import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { withStyles, WithStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GroupIcon from '@material-ui/icons/Group';
import ShareIcon from '@material-ui/icons/Share';
import ExploreIcon from '@material-ui/icons/Explore';
import TvRoundedIcon from '@material-ui/icons/TvRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import ScheduleIcon from '@material-ui/icons/Schedule';
import clsx from 'clsx';
import { Typography } from '../../../components/Typography';
import { Button, IconOnlyButton } from '../../../components/Button';
import styles from './meetingCard.styles';
import {
  DeleteMeetingModal,
  ShareMeetingModal,
} from '../../meetings/components';
import { MeetingCard } from '../../../components/MeetingCard';
import { ActionModal } from '../../../components/Modal';

interface IMeetingCardProps extends WithStyles<typeof styles> {
  meeting: any;
  openEditMeetingModal?: (data: any) => void;
  openViewMeetingModal: (data: any) => void;
  joinToMeetingButtonClick?: (data: any) => void;
  key?: any;
  removeMeeting?: (id: string) => void;
}

const AllMeetingCard = ({
  classes,
  meeting,
  openEditMeetingModal,
  openViewMeetingModal,
  joinToMeetingButtonClick,
  removeMeeting,
  key,
}: IMeetingCardProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isMeetingInfoOpen, setIsMeetingInfoOpen] = useState(false);
  const [isDeleteMeetingOpen, setIsDeleteMeetingOpen] = useState(false);
  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // const isInstant = meeting?.type === 'instant';
  // const isLivestreaming = meeting?.type === 'livestreaming';
  // const isScheduledEvent = meeting?.type === 'scheduledevent';

  const meetingName = meeting?.name;

  const _onClickDeleteButton = (e: any) => {
    e.stopPropagation();
    setOpenDeleteModal(true);
  };
  const _onClickDeleteModalClose = (e: any) => {
    e.stopPropagation();
    setOpenDeleteModal(false);
  };

  const InfoItem = ({ icon, text, maxWidth, customClass }: any) => {
    return (
      <div className={clsx(classes.infoItem, customClass)}>
        {icon}
        <Typography
          variant="body1"
          className={classes.infoItemText}
          style={{ maxWidth }}
        >
          {text}
        </Typography>
      </div>
    );
  };

  return (
    <MeetingCard customStyles={classes.meetingCard} key={key}>
      <Typography variant="caption" component="h3" className={classes.title}>
        {meeting?.name}
      </Typography>
      <div className={classes.meetingInfoTopWrapper}>
        <InfoItem
          icon={<PermContactCalendarIcon />}
          text={`${intl.formatMessage({
            id: 'host',
          })} : ${meeting?.host?.profile?.name}`}
          maxWidth={300}
        />
        <InfoItem
          icon={<GroupIcon />}
          text={intl.formatMessage(
            {
              id: 'count_participants',
            },
            {
              count: meeting?.members.length || 0,
            }
          )}
        />
        <InfoItem
          icon={<ExploreIcon />}
          text={`${intl.formatMessage({
            id: 'id',
          })} : ${meeting._id}`}
          // maxWidth={187}
        />
      </div>

      <div className={classes.meetingInfoBottomWrapper}>
        <div className={classes.infoItemsWrapper}>
          <InfoItem
            icon={<DateRangeRoundedIcon />}
            text={`${moment(meeting?.beginTime).format(
              'YYYY/MM/DD'
            )}\xa0\xa0 ${moment(meeting?.beginTime).format(
              'hh:mm A'
            )} - ${moment(meeting?.endTime).format('hh:mm A')}`}
          />
        </div>
        <div className={classes.buttonWrapper}>
          {meeting?.meetingType === 'upcoming' && (
            <IconOnlyButton onClick={() => setIsDeleteMeetingOpen(true)}>
              <DeleteForeverRoundedIcon />
            </IconOnlyButton>
          )}
          {isMediumScreen ? (
            <IconOnlyButton onClick={() => openViewMeetingModal(meeting)}>
              <VisibilityIcon />
            </IconOnlyButton>
          ) : (
            <Button
              variant="outlined"
              label={intl.formatMessage({
                id: 'view_details',
              })}
              onClick={() => openViewMeetingModal(meeting)}
            />
          )}
          {meeting?.meetingType !== 'past' && joinToMeetingButtonClick && (
            <Button
              variant="contained"
              color="primary"
              label={intl.formatMessage({
                id: meeting?.meetingType === 'ongoing' ? 'join' : 'launch',
              })}
              onClick={() => joinToMeetingButtonClick(meeting?.location)}
            />
          )}
          {meeting?.meetingType === 'past' && openEditMeetingModal && (
            <Button
              variant="contained"
              color="primary"
              label={intl.formatMessage({
                id: 'reschedule',
              })}
              onClick={() => openEditMeetingModal(meeting)}
            />
          )}
        </div>
      </div>
      {removeMeeting && (
        <DeleteMeetingModal
          open={isDeleteMeetingOpen}
          meeting={meeting}
          handleClose={() => setIsDeleteMeetingOpen(false)}
          removeMeeting={removeMeeting}
        />
      )}
      {isShareMeetingOpen && (
        <ShareMeetingModal
          open={isShareMeetingOpen}
          handleClose={() => setIsShareMeetingOpen(false)}
          meeting={meeting}
        />
      )}
      {removeMeeting && (
        <ActionModal
          modalTitle={intl.formatMessage({
            id: 'delete_stream',
          })}
          modalDescription={intl.formatMessage(
            {
              id: 'delete_stream_description',
            },
            {
              name: meetingName,
            }
          )}
          open={openDeleteModal}
          handleClose={_onClickDeleteModalClose}
          disableBackdropClick
          actinButtonOnClick={() => removeMeeting(meeting._id)}
          actinButtonLabel={intl.formatMessage({
            id: 'delete',
          })}
        />
      )}
    </MeetingCard>
  );
};

AllMeetingCard.defaultProps = {
  openEditMeetingModal: () => console.log('openEditMeetingModal called'),
  joinToMeetingButtonClick: () =>
    console.log('joinToMeetingButtonClick called'),
  removeMeeting: () => console.log('removeMeeting called'),
  key: '',
};

export default memo(withStyles(styles)(AllMeetingCard));
