/* eslint-disable no-unused-vars */
import React from 'react';
import { Switch, Theme } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '../Typography';

const CustomizedSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 33,
      height: 22,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          // backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.dark,
        },
      },
    },
    thumb: {
      width: 18,
      height: 18,
      boxShadow: 'none',
    },
    track: {
      borderRadius: 1000,
      opacity: 1,
      height: 'auto',
      backgroundColor: theme.palette.grey[400],
      borderColor: theme.palette.grey[400],
    },
    checked: {},
  })
)(Switch);

export interface IBasicSwitchProps {
  checked?: boolean;
  text?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  disabled?: boolean;
}

export const BasicSwitch: React.FC<IBasicSwitchProps> = ({
  checked = true,
  text = false,
  disabled = false,
  onChange,
}: IBasicSwitchProps) => {
  const intl = useIntl();
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CustomizedSwitch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {text && (
        <div>
          <Typography variant="body1" style={{ marginLeft: 5 }}>
            {intl.formatMessage({
              id: checked ? 'active' : 'deactive',
            })}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default BasicSwitch;
